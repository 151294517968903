<template>
  <a-row type="flex" justify="center">
    <!-- <div>舒悦隐私政策</div>
    <div>|</div>
    <div>权益保障承诺书</div>-->
    <div>Copyright © 2020-2021 杭州享住信息技术有限公司 版权所有</div>
    <a-divider type="vertical" style="margin-top:3px" />
    <a href="https://beian.miit.gov.cn/">浙ICP备20026879号-1</a>
  </a-row>
</template>

<script>
export default {
  name: 'copyright'
}
</script>
