/* eslint-disable */
import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '../views/login/login.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/login', name: 'login', component: login },
  { path: '/contract', name: 'contract', component: () => import("../views/order/contract.vue") },
  { path: '/refundcontract', name: 'refundcontract', component: () => import("../views/order/refundcontract.vue") },
  { path: '/register', name: 'register', component: () => import("../views/login/register.vue") },
  { path: '/password', name: 'password', component: () => import("../views/login/password.vue") },
  { path: '/phone', name: 'phone', component: () => import("../views/user/phone.vue") },
  { path: '/information', name: 'information', component: () => import("../views/user/information.vue") },
  {
    path: '/', name: 'index', component: () => import('../views/home/index.vue'), children: [
      { path: '', name: 'home', component: () => import("../views/home/home.vue") },
      { path: 'products', name: 'products', component: () => import("../views/product/products.vue") },
      { path: 'product', name: 'product', component: () => import("../views/product/product.vue") },
      { path: 'cart', name: 'cart', component: () => import("../views/order/cart.vue") },
      { path: 'customcart', name: 'customcart', component: () => import("../views/order/customcart.vue") },
      { path: 'orders', name: 'orders', component: () => import("../views/order/orders.vue") },
      { path: 'confirm', name: 'confirm', component: () => import("../views/order/confirm.vue") },
      { path: 'announcement', name: 'announcement', component: () => import("../views/home/announcement.vue") },
      { path: 'user', name: 'user', component: () => import("../views/user/user.vue") },
      { path: 'comment', name: 'comment', component: () => import("../views/user/comment.vue") },
      { path: 'invoice/apply', name: 'apply', component: () => import("../views/invoice/apply.vue") },
      { path: 'invoice/record', name: 'record', component: () => import("../views/invoice/record.vue") },
      { path: 'invoice/invoice', name: 'invoice', component: () => import("../views/invoice/invoice.vue") },
      { path: 'details', name: 'details', component: () => import("../views/order/details.vue") },
    ]
  },
  { path: "/:pathMatch(.*)", redirect: { name: "user" } },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
