/**
 * request.js
 * 定义了与请求相关的所有全局方法
 */
import Vue from 'vue'
import axios from 'axios'
import router from '../router'

const testMode = process.env.NODE_ENV === 'development'
const host = testMode ? '/host/' : 'https://store.chamjoy.com/chamjoy-mall-php/public/index.php/'

const onAxiosSuccess = (method, data, res, resolve, reject) => {
  // 调试模式时，打印接口返回
  if (testMode) {
    console.group(method, data)
    console.log(res.data.data)
    console.groupEnd()
  }
  if (res.data.code === 0) {
    // 正常
    resolve(res.data.data)
  } else if (res.data.code === 1003 || res.data.code === 1002) {
    // 登录态过期
    res.data.msg = '长时间未操作，请重新登录'
    router.replace('/login')
    reject(res.data)
  } else {
    // 其它错误
    reject(res.data)
    if (testMode) {
      Vue.prototype.$message.warn('调试提示：' + res.data.msg)
    }
  }
}

const onAxiosFail = (reject) => {
  reject(Vue.prototype.$message.error('网络连接失败'))
}

/**
 * 请求方式封装：GET
 * @param {string} method 方法名
 * @param {object} data 发送参数
 */
const get = (method, data = {}) => {
  return new Promise((resolve, reject) => {
    axios.get(host + method, { params: data }).then(res => {
      onAxiosSuccess(method, data, res, resolve, reject)
    }).catch(() => {
      onAxiosFail(reject)
    })
  })
}

/**
 * 请求方式封装：POST
 * @param {string} method 方法名
 * @param {object} data 发送参数
 */
const post = (method, data = {}) => {
  return new Promise((resolve, reject) => {
    axios.post(host + method, data).then(res => {
      onAxiosSuccess(method, data, res, resolve, reject)
    }).catch(() => {
      onAxiosFail(reject)
    })
  })
}

/**
 * 请求方式封装：POST-upload
 * @param {string} method 方法名
 * @param {object} data 发送参数
 */
var upload = (method, files) => {
  const formData = new FormData()
  for (const i in files) {
    formData.append('file[]', files[i])
  }
  return new Promise((resolve, reject) => {
    axios.post(host + method, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(res => {
      onAxiosSuccess(method, formData, res, resolve, reject)
    }).catch(() => {
      onAxiosFail(reject)
    })
  })
}

const install = () => {
  Vue.prototype.$host = host
  Vue.prototype.$axios = axios
  Vue.prototype.upload = upload
  Vue.prototype.post = post
  Vue.prototype.get = get
}

export default {
  install
}
