标题：分页器组件
作者：刘康宁
时间：2021.01.28
<template>
  <div>
    <a-pagination
      show-quick-jumper
      show-size-changer
      v-model="current"
      :total="size*total"
      @change="toPage"
      @showSizeChange="onPageSizeChange"
      style="margin: 20px 0"
    />
  </div>
</template>
<script>
export default {
  props: {
    method: String,
    params: Object,
    ok: Function,
    loading: Function
  },

  data() {
    return {
      size: 10,
      total: 1,
      current: 1
    }
  },

  mounted() {
    this.toPage(1)
  },

  methods: {
    toPage(page) {
      this.loading()
      const params = this.params || {}
      params.page = page
      params.number = this.size
      this.get(this.method, params)
        .then((res) => {
          this.current = page
          this.total = res.total
          this.ok(res.data)
        })
        .catch((err) => {
          this.$message.error(err.msg)
          this.ok([])
        })
    },
    onPageSizeChange(current, size) {
      this.size = size
      this.toPage(current)
    }
  }
}
</script>
