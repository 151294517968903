/**
 * main.js
 * 本文件含全局变量，不要修改！
 */
import Pagination from '@/component/Pagination.vue'
import Antd from 'ant-design-vue'
import Request from './api/request.js'
import Global from './api/global.js'
import VueLazyload from 'vue-lazyload'
import Vue from 'vue'
import App from './App'
import router from './router'
import 'ant-design-vue/dist/antd.less'
import VueDirectiveImagePreviewer from 'vue-directive-image-previewer'
import 'vue-directive-image-previewer/dist/assets/style.css'

Vue.component('Pagination', Pagination)

Vue.use(Antd)
Vue.use(Request)
Vue.use(Global)
Vue.use(VueDirectiveImagePreviewer, { zIndex: 1e3 })
Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: 'index/none.gif',
  loading: 'index/none.gif',
  attempt: 3
})

Vue.config.productionTip = false

router.afterEach((to, from, next) => {
  window.scrollTo(0, 0)
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
