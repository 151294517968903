<template>
  <div id="app">
    <a-config-provider :locale="locale">
      <router-view v-if="isRouterAlive" />
    </a-config-provider>
  </div>
</template>

<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
export default {
  data() {
    return {
      locale: zhCN,
      isRouterAlive: true
    }
  },
  provide() {
    // 提供
    return {
      reload: this.reload
    }
  },
  methods: {
    reload() {
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    }
  },
  mounted() {
    if (this.getUser() == null) {
      if (this.$route.name !== 'login' && this.$route.path !== '/') {
        this.$router.replace('/login')
      }
    }
  }
}
</script>

<style>
#app {
  min-width: 1200px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
  --font: #2c3e50;
  --theme: #347f9b;
  --warning: #e6a23c;
  --error: #f56c6c;
  --success: #67c23a;
  --info: #909399;
  --theme-light: #c3e6f3;
}

.white {
  color: #fff !important;
}
.theme {
  color: var(--theme) !important;
}
.black {
  color: var(--font) !important;
}
.red {
  color: var(--error) !important;
}
.yellow {
  color: var(--warning) !important;
}
.green {
  color: var(--success) !important;
}
.grey {
  color: var(--info) !important;
}
.bold {
  font-weight: bold;
}

h5,
h6 {
  color: #909399 !important;
}

/* 统一表单显示 */
.ant-advanced-search-form {
  padding: 24px;
  background: #fbfbfb;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
}
.ant-advanced-search-form .ant-form-item {
  display: flex;
}
.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}
.ant-advanced-search-form {
  margin: 20px 0 !important;
  width: 100% !important;
  padding: 20px !important;
}
.ant-table-wrapper {
  margin: 0 !important;
}
</style>
