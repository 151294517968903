/**
 * global.js
 * 定义了与通用相关的所有全局方法
 */
import Vue from 'vue'

var $appData = {
}

const getUser = () => {
  if (getCookie('FRANCHISEETOKEN') && getCookie('FRANCHISEEKEY') && getSession('user')) {
    return getSession('user')
  } else {
    delCookie('FRANCHISEETOKEN')
    delCookie('FRANCHISEEKEY')
    delSession('user')
    return null
  }
}

const setPageTitle = (that, array) => {
  setTimeout(() => {
    that.$root.$emit('pageTitle', array)
  }, 500)
}

const getCookie = name => {
  var cookieString = document.cookie; var cookies = {}
  var reg, result
  if (!/;\s*$/.test(cookieString)) cookieString += ';'
  if (name) {
    reg = new RegExp(name + '=([\\w\\W]*?);')
    result = reg.exec(cookieString)
    return result ? (result[1]) : null
  } else {
    reg = /(\S+?)=([\w\W]*?);/g
    while (result === reg.exec(cookieString)) { cookies[result[1]] = (result[2]) }
    return cookies
  }
}

const setCookie = (cookieName, value, expireSeconds) => {
  var expires = new Date()
  expires.setTime(expires.getTime() + expireSeconds * 1e3)
  console.log(expires.getTime(), expires, expireSeconds)
  document.cookie = cookieName + '=' + encodeURIComponent(value) + ((expireSeconds == null) ? '' : ';expires=' + expires.toGMTString())
}

const delCookie = name => {
  var cval = getCookie(name)
  if (cval != null) { document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;' }
}

const getSession = name => {
  return JSON.parse(sessionStorage.getItem(name))
}
const delSession = name => {
  return sessionStorage.removeItem(name)
}

const setSession = (name, obj) => {
  return sessionStorage.setItem(name, JSON.stringify(obj))
}

const install = () => {
  Vue.prototype.$appData = $appData
  Vue.prototype.getUser = getUser
  Vue.prototype.setPageTitle = setPageTitle
  Vue.prototype.setCookie = setCookie
  Vue.prototype.getCookie = getCookie
  Vue.prototype.delCookie = delCookie
  Vue.prototype.setSession = setSession
  Vue.prototype.getSession = getSession
  Vue.prototype.delSession = delSession
}

export default {
  install
}
