<template>
  <div class="main-body">
    <a-spin :spinning="spinning" tip="正在连接商城">
      <img class="bg" @load="onImageLoad" src="logo/login.jpg" alt />
      <div class="body">
        <div class="top">
          <a-row class="middle_title">
            <img class="logo_foot" :src="`${publicPath}logo/icon128.png`" />
            <a-col style="margin-left:20px">
              <a-row type="flex">舒悦商城</a-row>
              <a-row type="flex" style="font-size:15px">CHAMJOY-MALL</a-row>
            </a-col>
          </a-row>
          <a type="flex" class="to-home-page" @click="goMainPage">
            <a-icon type="home" style="fontSize:20px;marginLeft:auto;color:#ffffff" />
            <a style="fontSize:18px;margin:3px 50px 0 5px;color:#ffffff">首页</a>
          </a>
          <div class="qrcodes-body">
            <a-col class="qrcode_box">
              <img class="qrcode" :src="`${publicPath}logo/qrcode.jpg`" alt="二维码" />
              <div class="qrcode-title">商城小程序</div>
            </a-col>
            <a-col class="qrcode_box">
              <img class="qrcode" :src="`${publicPath}logo/qrcode.jpg`" alt="二维码" />
              <div class="qrcode-title">商城公众号</div>
            </a-col>
            <a-col class="qrcode_box">
              <img class="qrcode" :src="`${publicPath}logo/qrcode.jpg`" alt="二维码" />
              <div class="qrcode-title">舒悦公众号</div>
            </a-col>
          </div>
        </div>
        <div class="middle">
          <div style="display:flex;marginLeft:auto">
            <a-row class="menu right" type="flex" align="middle">
              <!-- 登录 -->
              <a-col>
                <!-- <a-row class="menu_title">
                  <span style="font-size:20px" class="theme bold">舒悦物资采购平台-门店登录</span>
                </a-row>-->
                <!-- </a-row> -->
                <div style="width: 300px">
                  <a-input size="large" v-model="name" placeholder="请输入用户名" style="margin: 15px 0">
                    <a-icon slot="prefix" type="mobile" />
                  </a-input>
                  <a-input size="large" v-model="password" placeholder="请输入密码" type="password">
                    <a-icon slot="prefix" type="lock" />
                  </a-input>
                  <a-input
                    size="large"
                    v-model="captcha"
                    placeholder="请输入验证码"
                    style="margin: 15px 0"
                  >
                    <a-icon slot="prefix" type="build" />
                    <div slot="suffix">
                      <img
                        mode="aspectFit"
                        :src="imageVertification"
                        class="image-vertification"
                        @click.stop="getImageValidate"
                      />
                    </div>
                  </a-input>
                  <!-- <div v-if="(!this.name) || (!this.password)"> -->
                  <a-checkbox @change="onChange" style="margin-top:30px">
                    我已阅读并同意
                    <a
                      class="notification"
                      target="_blank"
                      @click.stop="toGaoZhiShu"
                    >《舒悦商城告知书》</a>
                  </a-checkbox>
                  <a-button
                    class="title_button"
                    :loading="isLoginLoading"
                    @click="login"
                    type="primary"
                    size="large"
                    style="width: 100%; margin: 15px 0"
                  >登录</a-button>
                </div>
                <a-row type="flex" justify="space-between">
                  <a class="title" @click="$router.push('/password')">忘记密码</a>
                  <div style="text-align: right">
                    <a class="title" @click="$router.push('/register')">免费注册</a>
                    <br />
                  </div>
                </a-row>
                <a-row type="flex" justify="space-between">
                  <div
                    style="margin-top:20px;font-size:15px;"
                    :key="i.label"
                    v-for="i in [
              { label: '供应商登录', url: 'supplier' },
              { label: '施工方登录', url: 'builder' },
            ]"
                    @click="open(i.url)"
                  >
                    <a class="title" style="color:red">{{ i.label }}</a>
                  </div>
                </a-row>
              </a-col>
              <div
                style="position: absolute; bottom: 25px; text-align: center; font-size: 10px;left:0;right:0;"
              >
                <a
                  class="title"
                  style="text-decoration:underline"
                  @click="isShowHelp = true"
                >登录显示问题帮助？</a>
              </div>
            </a-row>
          </div>
        </div>

        <div class="bottom">
          <a-row type="flex" style="font-size: 15px;color:white">
            <a-col class="bottom-link">
              <a-dropdown>
                <a
                  class="ant-dropdown-link"
                  @click="e => e.preventDefault()"
                  style="color:#ffffff"
                >关于我们</a>
                <a-menu slot="overlay">
                  <a-menu-item key="1">
                    <a class="title" href="https://www.chamjoy.com">公司简介</a>
                  </a-menu-item>
                  <a-menu-item key="2">
                    <a class="title" href="https://www.chamjoy.com">营业执照</a>
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </a-col>
            <a-divider type="vertical" style="margin-top:5px" />
            <a-col class="bottom-link">
              <a-dropdown>
                <a
                  class="ant-dropdown-link"
                  @click="e => e.preventDefault()"
                  style="color:#ffffff"
                >服务指南</a>
                <a-menu slot="overlay">
                  <a-menu-item key="1">
                    <a class="title" href="https://www.chamjoy.com">付款及开票流程</a>
                  </a-menu-item>
                  <a-menu-item key="2" class="title" href="https://www.chamjoy.com">
                    <a class="title" href="https://www.chamjoy.com">供应商结算流程</a>
                  </a-menu-item>
                  <a-menu-item key="3" class="title" href="https://www.chamjoy.com">
                    <a class="title" href="https://www.chamjoy.com">采购下单流程</a>
                  </a-menu-item>
                  <a-menu-item key="4" class="title" href="https://www.chamjoy.com">
                    <a class="title" href="https://www.chamjoy.com">退换货流程</a>
                  </a-menu-item>
                  <a-menu-item key="5" class="title" href="https://www.chamjoy.com">
                    <a class="title" href="https://www.chamjoy.com">客户服务</a>
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </a-col>
            <a-divider type="vertical" style="margin-top:5px" />
            <a-col class="bottom-link">
              <a-dropdown>
                <a
                  class="ant-dropdown-link"
                  @click="e => e.preventDefault()"
                  style="color:#ffffff"
                >平台政策</a>
                <a-menu slot="overlay">
                  <a-menu-item key="1">
                    <a
                      class="title"
                      target="_blank"
                      href="https://store.chamjoy.com/fuwuxieyi.html"
                    >服务协议</a>
                  </a-menu-item>
                  <a-menu-item key="2" class="title">
                    <a
                      class="title"
                      target="_blank"
                      href="https://store.chamjoy.com/yinsizhengche.html"
                    >隐私政策</a>
                  </a-menu-item>
                  <a-menu-item key="3" class="title">
                    <a
                      class="title"
                      target="_blank"
                      href="https://store.chamjoy.com/zhucexieyi.html"
                    >注册协议</a>
                  </a-menu-item>
                  <a-menu-item key="4" class="title">
                    <a
                      class="title"
                      target="_blank"
                      href="https://store.chamjoy.com/jiaoyiguize.html"
                    >交易规则</a>
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </a-col>
            <a-divider type="vertical" style="margin-top:5px" />
            <a-col class="bottom-link">
              <a-dropdown>
                <a
                  class="ant-dropdown-link"
                  @click="e => e.preventDefault()"
                  style="color:#ffffff"
                >商务合作</a>
                <a-menu slot="overlay">
                  <a-menu-item key="1">
                    <a class="title" href="https://store.chamjoy.com/supplier/login">供应商注册</a>
                  </a-menu-item>
                  <a-menu-item key="2">
                    <a class="title" href="https://store.chamjoy.com/builder/login">施工方注册</a>
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </a-col>
            <a-divider type="vertical" style="margin-top:5px" />
            <a-col class="bottom-link">
              <a-dropdown>
                <a
                  class="ant-dropdown-link"
                  @click="e => e.preventDefault()"
                  style="color:#ffffff"
                >友情链接</a>
                <a-menu slot="overlay">
                  <a-menu-item key="1">
                    <a class="title" href="https://www.chamjoy.com">舒悦商学院</a>
                  </a-menu-item>
                  <a-menu-item key="2">
                    <a class="title" href="https://www.chamjoy.com">舒悦酒店</a>
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </a-col>
          </a-row>
        </div>
        <div class="bottom">
          <a-row type="flex" justify="center" class="foot">
            <span style="width: 650px">
              <Copyright />
            </span>
          </a-row>
        </div>
        <a-modal v-model="isShowHelp" title="帮助" :footer="null">
          <p>建议使用谷歌 Chrome 浏览器获得最佳体验。</p>
          <a @click="download" style="color: #f00 !important">
            点击下载：
            <span style="text-decoration: underline">谷歌浏览器</span>
            <span style="color: #999 !important">（下载资源由舒悦商学院提供）</span>
          </a>
          <p style="margin-top: 30px">360或搜狗浏览器用户：请点击右上角打开极速模式。</p>
          <img src="@/assets/help.jpg" alt="帮助" />
        </a-modal>
      </div>
    </a-spin>
  </div>
</template>
<script>
import Copyright from '@/component/home/Copyright.vue'
import axios from 'axios'
export default {
  components: {
    Copyright
  },

  data() {
    return {
      publicPath: process.env.BASE_URL,
      isShowHelp: false, // 帮助弹框
      spinning: true,
      isLoginLoading: false,
      name: '',
      password: '',
      // 验证码
      imageVertification: '',
      captcha: '',
      // 是否勾选告知书
      isChecked: false
    }
  },
  methods: {
    onChange(e) {
      this.isChecked = e.target.checked
      // console.log(`checked = ${e.target.checked}`)
    },
    download() {
      window.open('https://mooc.chamjoy.com/chrome.exe', '_blank')
    },
    openUrl(url) {
      window.open(url, '_blank')
    },
    onImageLoad() {
      this.spinning = false
    },
    goMainPage() {
      this.$router.replace({
        name: 'home'
      })
    },
    login() {
      if (!this.name) return this.$message.warn('请输入用户名')
      if (!this.password) return this.$message.warn('请输入密码')
      if (!this.captcha) return this.$message.warn('请输入验证码')
      if (!this.isChecked) return this.$message.warn('请同意《舒悦商城告知书》')
      this.isLoginLoading = true
      this.post('loginForFranchisee', {
        phone: this.name,
        password: this.password,
        captcha: this.captcha
      })
        .then((res) => {
          this.isLoginLoading = false
          if (res.status === 4) {
            this.$router.replace('/information')
          } else {
            this.$router.replace({
              name: 'home',
              params: { showAnnouncement: true }
            })
          }
          this.setSession('user', res)
        })
        .catch((err) => {
          this.isLoginLoading = false
          switch (err.code) {
            case 4109:
              this.$message.error('用户名未注册，请先申请注册')
              break
            case 1006:
              this.$message.error('用户名或密码有误')
              break
            case 1004:
              this.$message.error('验证码错误')
              break
          }
          this.getImageValidate()
        })
    },
    getImageValidate() {
      const url = this.imageVertification
      this.imageVertification = ''
      axios.get(url).then(() => {
        this.imageVertification = url
      })
    },
    toGaoZhiShu() {
      window.open('https://store.chamjoy.com/gaozhishu.html', '_blank')
    },
    // 打开其他链接
    open(url) {
      window.location.href = 'https://store.chamjoy.com/' + url
    }
  },
  mounted() {
    this.imageVertification =
      (process.env.NODE_ENV === 'development'
        ? '/host/'
        : 'https://store.chamjoy.com/chamjoy-mall-php/public/index.php/') +
      'getImageValidate'
    document.title = '登录舒悦商城'
    this.getImageValidate()
  }
}
</script>
<style scoped>
.main-body {
  display: flex;
  justify-content: center;
  height: calc(100vh);
  /* align-content: center; */
  align-items: center;
}
.body {
  min-width: 1000px;
  max-width: 1200px;
  margin: auto;
}
/* 第一层 */
.top {
  display: flex;
  /* justify-content: space-between; */
  color: #fff;
  font-size: 30px;
  /* margin-left: 250px; */
  /* margin-top: 20px; */
}
/* 第二层 */
.middle {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}
.middle_title {
  color: #fff;
  font-size: 30px;
  display: flex;
  align-items: center;
  flex: 1;
  /* margin-left: 130px; */
  /* margin-top: 20px; */
}
.menu_title {
  display: flex;
  justify-content: center;
  align-items: center;
}
/* 第三层 */
.bottom {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-left: 20px;
}
.menu {
  position: relative;
  /* margin-right: 90px; */
  opacity: 0.9;
  padding: 0 40px;
  min-width: 350px;
  min-height: 480px;
}
/* .left {
  background: linear-gradient(20deg, #16a085 10%, var(--theme) 60%);
  border-radius: 20px 0 0 20px;
} */
.right {
  background: #f7f7fd;
  /* border-radius: 0 20px 20px 0; */
  border-radius: 20px 20px 20px 20px;
}
.bg {
  object-fit: cover;
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-width: 1000px;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-position: center;
  filter: blur(20px);
}
.logo {
  width: 160px;
  margin-top: -20px;
}
.foot {
  text-align: center;
  position: fixed;
  width: 100%;
  bottom: 10px;
  font-size: 10px;
  color: #fff;
}
/* 二维码 */
.qrcode {
  width: 70px;
}
.qrcode-title {
  font-size: 15px;
  margin-top: 5px;
}
.qrcode_box {
  color: #fff;
  /* margin-left: 20px; */
  text-align: center;
}
.logo_foot {
  width: 80px;
  float: left;
}
/* 文字光标样式 */
.title:hover {
  color: darkblue;
  text-decoration: underline;
}
.title_button:hover {
  background-color: mediumturquoise;
}
.to-home-page {
  display: flex;
  align-items: center;
  flex: 1;
}
.to-home-page:hover {
  text-decoration: underline;
}
.qrcodes-body {
  display: flex;
  justify-content: space-between;
  flex: 1;
  padding: 0 30px 0 0;
}
.notification:hover {
  text-decoration: underline;
  color: darkblue;
}
.bottom-link {
  display: flex;
  width: 100px;
  flex-direction: column;
  align-items: center;
}
.image-vertification {
  width: 120px;
}
</style>
